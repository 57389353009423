import TomSelect from 'tom-select/dist/esm/tom-select.js';
import 'tom-select/dist/scss/tom-select.default.scss';
import TomSelect_remove_button from 'tom-select/dist/esm/plugins/remove_button/plugin.js';

TomSelect.define('remove_button', TomSelect_remove_button);

function loadAutocompleteInput(el) {
  var threshold = 3;

  var settings = {
    plugins: ['remove_button'],
    valueField: 'name',
    searchField: 'name',
    create: true,
    createOnBlur: true,
    maxItems: 1,
    dataAttr: 'data-id',
    preload: false,
    persist: false,
    load: function(query, callback) {
      this.clearOptions();

      var params = {
        query: query,
      };

      $.ajax({
        url: el.dataset.neoAutocompleteUrl,
        cache: false,
        data: params,
        dataType: 'jsonp',
        success: function(data) {
          if (data.errors) {
            console.log(data.errors);
            callback();
          } else {
            callback(data.results);
          }
        },
      });
    },
    // Disable scoring and assuming server send only matching results
    score: function() {
      return function() {
        return 1;
      };
    },
    render: {
      no_results: null,
      option: function(item, escape) {
        return (
          "<div class='company-template'>" +
          "<div class='name'>" +
          escape(item.name) +
          '</div>' +
          "<div class='address'>" +
          escape(item.formatted_address || '') +
          '</div>' +
          '</div>'
        );
      },
      item: function(data, escape) {
        return "<div data-neo-autocomplete-value='" + (data.id || '') + "'>" + escape(data.name) + '</div>';
      },
      option_create: null,
    },
    onItemAdd: function(_value, item) {
      var hiddenInput = this.input.closest('form').querySelector('[data-neo-autocomplete-target]');
      hiddenInput.value = item.dataset.neoAutocompleteValue;
    },
    onItemRemove: function() {
      var hiddenInput = this.input.closest('form').querySelector('[data-neo-autocomplete-target]');
      hiddenInput.value = '';
    },
  };

  var select = new TomSelect(el, settings);
}

export function loadAutocompleteInputs() {
  document.querySelectorAll('[data-neo-autocomplete-url]').forEach(loadAutocompleteInput);
}

// Global scope to get used by non-webpack compliant pages (See landing page widgets)
window.loadAutocompleteInputs = loadAutocompleteInputs;
